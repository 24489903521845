<template>
    <div class="demo">
        <iframe id="intelligenceIframe"></iframe>
    </div>
</template>

<style scoped lang="scss">
.demo {
    display: flex;
    height: 100%;
    width: 100%;
    color: #333;
    text-shadow: none;
    justify-content: center;
    align-items: center;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}
</style>
<script>
import { encode } from 'js-base64';
export default {
    data() {
        return {
            iframe: ''
        };
    },
    props: {
        app: Object
    },
    created() {
        this.$emit('api', {
            event: 'windowMaxSize'
        });
    },
    mounted() {
        this.iframe = document.getElementById('intelligenceIframe');

        const token = localStorage.getItem('access_token') || '';

        // 优先读取缓存的地址
        const value = localStorage.getItem(this.app.key);
        if (value) {
            this.iframe.src = value + `?k=${encode(token)}`;
        } else {
            this.iframe.src = this.app.url + `?k=${encode(token)}`;
        }
    }
};
</script>
